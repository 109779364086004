import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Store } from '@ngrx/store';

import { AppState } from '../../app.reducer';
import { AiAction } from './ai.actions';
import * as selectors from './ai.selectors';
import { RequestDiffRecord } from '../shared';
import { TreeNode } from '../../../core/interface/core.interface';
import { Search } from '../../../ai/interface/ai.interface';
import { AIFindDocumentsPayload } from '../../../ai/components/ai.findDocuments.component';

@Injectable()
export class AiService {

  public isBusy: Observable<number>;
  public isBusyIPC: Observable<number>;
  public diff: Observable<RequestDiffRecord>;

  public constructor(private store: Store<AppState>, private action: AiAction) {
    this.isBusy = this.getAPIState().let(selectors.getIsBusy());
    this.diff = this.getAPIState().let(selectors.getDiff());
  }

  public analyseDocument(businessAreaId: string, modelId: string, structure: string, ignore: string, engine: string, files: FileList, statusNode: TreeNode) {
    this.store.dispatch(this.action.analyse(businessAreaId, modelId, structure, ignore, engine, files, statusNode));
  }

  public getSimilarityIndices(requestId: string, input: string[], limit = 0) {
    this.store.dispatch(this.action.similarity(requestId, input, limit));
  }

  public getChatGPTResponse(requestId: string, payload) {
    this.store.dispatch(this.action.chatGPTRequest(requestId, payload));
  }

  public doSearch(payload: Search) {
    this.store.dispatch(this.action.search(payload));
  }

  public findDocuments(requestId: string, input: AIFindDocumentsPayload) {
    this.store.dispatch(this.action.findDocuments(requestId, input));
  }

  public stopFindDocuments(requestId: string, stopId: string) {
    this.store.dispatch(this.action.stopFindDocuments(requestId, stopId));
  }

  public gpt(url: string, data: any, options?: any) {
    this.store.dispatch(this.action.gptRequest(url, data, options));
  }

  protected getAPIState() {
    return this.store.select((state: AppState) => state.api);
  }

}
