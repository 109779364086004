import { ColorLabelProviderCommon } from '../colorlabelprovider.common';
import { ColorLabelProviderLegend } from '../colorlabelprovider.interface';
import { TranslateService } from '@ngx-translate/core';
import { TreeNode } from '../../../core/interface/core.interface';

export class ColorLabelProviderAbstract {

  public complex = false;

  protected ignore = ['name', ''];

  public constructor(protected translateService?: TranslateService) {}

  public color(...args: any[]): any {
    return [ColorLabelProviderCommon.COLOR];
  }

  public label(...args: any[]): any {
    return ColorLabelProviderCommon.LABEL;
  }
  
  public legend(...args: any[]): any {
    return <ColorLabelProviderLegend[]>[];
  }

  public modifyConfiguration(configurationNode: TreeNode): any {}

}
